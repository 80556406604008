import { ResponseSuccess } from '../response.model';
import { ReferenceTableItem } from '@app/models/settings/reference-table.model';

export interface CaregiverPreferences {
  id: number;
  profile: CaregiverPreferencesProfile;
}

export interface CaregiverPreferencesUpdate {
  id: number;
  profile_data: CaregiverPreferencesProfile;
}

export interface CaregiverPreferencesProfile {
  id: number;
  animals: number[];
  work_with_smoker: boolean;
  smokers_preferences: SmokerType[];
  preferred_gender: number;
  able_to_drive: boolean;
  people_with_diseases: number[];
  animals_details?: AnimalDetails[];
  people_with_diseases_details?: DiseaseDetails[];
  equipment_reference_type: number[];
  equipment_type_details: ReferenceTableItem[];
}

export interface AnimalDetails {
  id: number;
  name: string;
  code: string;
}

export interface DiseaseDetails {
  id: number;
  value: string;
}

export interface PinCode {
  readonly pin_code: number;
}

export interface SwitchBlastingResponse extends ResponseSuccess {
  enable_blasting: boolean;
}

export enum SmokerType {
  Tobacco,
  Cigars,
  Vape,
  Hookah,
  Marijuana
}
