import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { SettingsService } from '../services/settings/settings.service';
import { ReferenceType } from '../enums';
import { toPaginatedResponse } from '../rx-operators';
import { ReferenceTableItem, ReferenceTableRequestParams } from '@app/models/settings/reference-table.model';

export class EquipmentsOptionsLoader implements OptionsLoader {
  private settingsService = inject(SettingsService);

  getOptions(params?: ReferenceTableRequestParams): Observable<PaginatedResponse<ReferenceTableItem>> {
    return this.settingsService.getReferenceTable({
      ...(params ?? {}),
      reference_type: ReferenceType.EQUIPMENT
    }).pipe(toPaginatedResponse());
  }
}
